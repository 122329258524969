<template>
    <div class="f1 apps-page w-full dF fC f1 px-4">
        <div class="dF aC jSB">
            <div class="dF aC f1">
                <h3>Users</h3>
            </div>
			<div class="dF aC">
                <a-button @click="downloadUsers" primary>
					<a-icon type="download"></a-icon>
					Download
				</a-button>
				<a-button @click="downloadAllUsers" primary class="ml-3">
					<a-icon type="download"></a-icon>
					Download All
				</a-button>
				<div>
					<div @click="showFilter" class="dF aC px-3 py-2 ml-3" style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer; line-height: normal;">
						<div class="mr-2">Filters</div>
						<i class="fe fe-filter" />
					</div>
				</div>
            </div>
        </div>
        <a-card :bodyStyle="{ padding: 0 }" class="my-3">
            <div>
                <a-table
                    :rowKey="(e) => e.key"
                    :columns="columns"
                    :row-selection="rowSelection"
                    :dataSource="users"
                    :loading="loading"
                    :pagination="{
                        current: currentPage,
                        total: totalUsers,
                        pageSize: pageSize,
						showSizeChanger: true,
						pageSizeOptions: ['10', '20', '30', '40', '50', '75', '100']
                    }"
                    @change="handleChange"
                    :scroll="{ x: 1350 }"
                >
                    <div slot="readableId" slot-scope="user">
                        {{ user.readableId }}
                    </div>
                    <div slot="username" slot-scope="user">
                        {{ user.username }}
                    </div>
                    <div slot="email" slot-scope="user">
                        {{ user.email }}
                    </div>

                    <div slot="userRole" slot-scope="user">
                        {{ user.userRole }}
                    </div>
                    <div slot="company" slot-scope="user">
                        {{ user.company }}
                    </div>
                    <div slot="phone" slot-scope="user">
                        {{ user.phone }}
                    </div>
                    <div slot="userType" slot-scope="user">
                        <div
                            v-for="(type, index) in getUserType(user)"
                            :key="index"
                            :class="{ 'mt-2': index !== 0 }"
                        >
                            <span
                                class="project-button"
                                :style="{
                                    'background-color':
                                        typeStyle[type].backgroundColor,
                                    color: typeStyle[type].color,
                                }"
                                >{{ type }}</span
                            >
                        </div>
                    </div>
					<div slot="lastLoginMethod" slot-scope="user">
						<a-tag v-if="user.lastLoginMethod">{{ providers[user.lastLoginMethod] || user.lastLoginMethod }}</a-tag>
						<a-tag v-else>{{ providers[user.provider] || user.provider }}</a-tag>
						<br>
						<a-tag v-if="user['2faEnabled']" class="mt-3">2FA</a-tag>
					</div>
                    <div
                        slot="lastLoginDate"
                        slot-scope="user"
                        style="color: var(--med-gray)"
                    >
                        {{ user.lastLoginDate && formatDate(user.lastLoginDate) }}
                    </div>
					<div
                        slot="createdAt"
                        slot-scope="user"
                        style="color: var(--med-gray)"
                    >
                        {{ formatDate(user.createdAt) }}
                    </div>
                    <div slot="industry" slot-scope="user">
                        {{ user.industry }}
                    </div>
                    <div
                        slot="actions"
                        slot-scope="user"
                        class="dF"
                        style="justify-content: flex-end"
                    >
                        <a-popover
                            trigger="hover"
                            placement="bottomRight"
                            overlayClassName="popoverStyle"
                        >
                            <div slot="content">
                                <div
                                    class="popoverContent dF aC"
                                    @click="editUser(user)"
                                >
                                    Edit User
                                </div>
								<div
                                    class="popoverContent dF aC"
                                    @click="resendVerification(user)"
                                >
									Resend Email Verification
                                </div>
                                <div
                                    class="popoverContent dF aC"
                                    @click="
                                        selectedUser = user;
                                        resetPasswordModal = true;
                                    "
                                >
                                    Reset Password Access
                                </div>
                            </div>
                            <div class="more-option-icon mr-4">
                                <a-icon style="line-height: 40px" type="more" />
                            </div>
                        </a-popover>
                    </div>
                </a-table>
            </div>
        </a-card>
        <a-modal
            v-model="resetPasswordModal"
            title="Reset Password"
            ok-text="SEND"
            @ok="resetPassword"
            :centered="true"
        >
            <p>
                Are you sure you want to send reset password email to
                <strong>
                    {{ selectedUser.email }}
                </strong>
                ?
            </p>
        </a-modal>
        <a-modal
            v-model="createNewUser"
            title="Add New User"
            ok-text="CREATE"
            @ok="createUser"
            :confirmLoading="confirmLoading"
            :width="1000"
        >
            <a-form-model ref="ruleForm" :model="user" :rules="rules">
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            ref="firstName"
                            label="First Name"
                            prop="firstName"
                        >
                            <a-input
                                v-model="user.firstName"
                                placeholder="First Name"
                                size="large"
                                required
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            ref="lastName"
                            label="Last Name"
                            prop="lastName"
                        >
                            <a-input
                                v-model="user.lastName"
                                placeholder="Last Name"
                                size="large"
                                required
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            ref="email"
                            label="Email Address"
                            prop="email"
                        >
                            <a-input
                                v-model="user.email"
                                placeholder="Email Address"
                                size="large"
                                required
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="phone"
                            label="Phone Number"
                            required
                        >
                            <a-input
                                v-model="user.phone"
                                placeholder="Phone Number"
                                size="large"
                                type="number"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="userRole"
                            label="Your Role"
                            required
                        >
                            <a-input
                                placeholder="Your Role"
                                size="large"
                                v-model="user.userRole"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            prop="company"
                            label="Company Name"
                            required
                        >
                            <a-input
                                v-model="user.company"
                                placeholder="Company Name"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            prop="industry"
                            label="Business Type"
                            required
                        >
                            <a-select
                                v-model="user.industry"
                                size="large"
                                placeholder="Select Business Type"
                            >
                                <a-select-option value="Builder">
                                    Builder
                                </a-select-option>
                                <a-select-option value="Real Estate Broker">
                                    Real Estate Broker
                                </a-select-option>
                                <a-select-option value="Advertising Agency">
                                    Advertising Agency
                                </a-select-option>
                                <a-select-option value="Freelancer">
                                    Freelancer
                                </a-select-option>
                                <a-select-option value="Other">
                                    Other
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            prop="address"
                            label="Address"
                            required
                        >
                            <a-input
                                placeholder="Address"
                                size="large"
                                v-model="user.address"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            prop="country"
                            label="Country"
                            required
                        >
                            <a-select
                                show-search
                                size="large"
                                class="dropdown-style"
                                v-model="user.country"
                                @change="
                                    user.region = null;
                                    user.postal = null;
                                "
                            >
                                <a-select-option
                                    v-for="country in countries"
                                    :key="country.value"
                                    :value="country.name"
                                >
                                    {{ country.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item prop="city" label="City" required>
                            <a-input
                                placeholder="City"
                                size="large"
                                v-model="user.city"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="region"
                            label="State/Province"
                            required
                        >
                            <a-input
                                placeholder="State/Province"
                                size="large"
                                v-model="user.region"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="postal"
                            label="Zip/Postal Code"
                            required
                        >
                            <a-input
                                placeholder="Zip/Postal Code"
                                size="large"
                                v-model="user.postal"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-modal>
		<FilterUserDrawer :visible="filter" @close="closeFilter" @filter="applyFilter" />
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import {formatDate} from 'bh-mod'
import postalCodes from "postal-codes-js";
import countryData from "@/staticData/countryData";
import FilterUserDrawer from "@/components/common/FilterUserDrawer";
import _ from "lodash";
import { get } from "store";

export default {
	components: { FilterUserDrawer },
    data() {
        return {
			filter:{
				filter:{
					emails:[],
					company:[],
					loginType:['any']
				},
				visible:false
			},
            sortedInfo: {
                key: "createdAt",
                order: "DESC",
            },
            selectedRowKeys: [],
			selectedRowKeysPerPage: {},
            users: [],
			selectedUsers: {},
            loading: false,
            resetPasswordModal: false,
            confirmLoading: false,
            currentPage: 1,
            totalUsers: 0,
            pageSize: 10,
            selectedUser: {},
            countries: countryData.countryList,
            typeStyle: {
                Partner: {
                    backgroundColor: "#dddcfc",
                    color: "#7874e9",
                },
                Customer: {
                    backgroundColor: "#bfeaff",
                    color: "#2C94C8",
                },
                Contributor: {
                    backgroundColor: "#f8e1e3",
                    color: "#dc3545",
                },
            },
            user: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                company: "",
                industry: "",
                userRole: "",
                address: "",
                country: "",
                region: "",
                city: "",
                postal: "",
            },
			providers: {
				local: 'Email & Password',
				microsoft: 'Microsoft',
				google: 'Google',
				sso: 'SSO'
			}
        };
    },
    computed: {
        createNewUser: {
            get() {
                return this.$store.state.addNew["users"];
            },
            set() {
                this.UPDATE_ADD_NEW({
                    key: "users",
                    value: false,
                });
            },
        },

        search() {
            return this.$store.state.searchFilter.toLowerCase();
        },
        columns() {
            return [
                {
                    title: "User ID",
                    key: "readableId",
                    scopedSlots: { customRender: "readableId" },
                    sorter: true,
                },
                {
                    title: "Username",
                    key: "username",
                    scopedSlots: { customRender: "username" },
                    sorter: true,
                },
                {
                    title: "Email Address",
                    key: "email",
                    scopedSlots: { customRender: "email" },
                    sorter: true,
                },
                {
                    title: "Role",
                    key: "userRole",
                    scopedSlots: { customRender: "userRole" },
                    sorter: true,
                },
                {
                    title: "Company",
                    key: "company",
                    scopedSlots: { customRender: "company" },
                    sorter: true,
                },
                {
                    title: "Phone No.",
                    key: "phone",
                    scopedSlots: { customRender: "phone" },
                    sorter: true,
                },
                {
                    title: "User Type",
                    key: "userType",
                    scopedSlots: { customRender: "userType" },
                },
				{
                    title: "Date Created",
                    key: "createdAt",
                    scopedSlots: { customRender: "createdAt" },
                    sorter: true,
                },
				{
					title: "Last Login Method",
					key: "lastLoginMethod",
					scopedSlots: { customRender: "lastLoginMethod" },
					sorter: true,
				},
                {
                    title: "Last Login Date",
                    key: "lastLoginDate",
                    scopedSlots: { customRender: "lastLoginDate" },
                    sorter: true,
                },
                {
                    title: "Business Type",
                    key: "industry",
                    scopedSlots: { customRender: "industry" },
                    sorter: true,
                },
                {
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
                },
            ];
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: false,
                onSelection: this.onSelection,
            };
        },

        rules() {
            return {
                email: [
                    { validator: this.validateEmail, trigger: "change" },
                    {
                        required: true,
                        message: "Please enter your email",
                        trigger: "change",
                    },
                ],
                postal: [
                    { validator: this.validatePostalCodes, trigger: "change" },
                    {
                        required: true,
                        message: "Please enter your Postal code",
                        trigger: "change",
                    },
                ],
                firstName: [
                    {
                        required: true,
                        message: "Please enter your first name",
                        trigger: "change",
                    },
                ],
                lastName: [
                    {
                        required: true,
                        message: "Please enter your last name",
                        trigger: "change",
                    },
                ],
                userRole: [
                    {
                        required: true,
                        message: "Please enter your role",
                        trigger: "change",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "Please enter your business number",
                        trigger: "change",
                    },
                ],
                company: [
                    {
                        required: true,
                        message: "Please enter your company name",
                        trigger: "change",
                    },
                ],
                industry: [
                    {
                        required: true,
                        message: "Please select your business type",
                        trigger: "change",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "Please enter your address",
                        trigger: "change",
                    },
                ],
                country: [
                    {
                        required: true,
                        message: "Please select your country",
                        trigger: "change",
                    },
                ],
                region: [
                    {
                        required: true,
                        message: "Please enter your region",
                        trigger: "change",
                    },
                ],
                city: [
                    {
                        required: true,
                        message: "Please enter your city",
                        trigger: "change",
                    },
                ],
            };
        },
    },
    watch: {
        search() {
            this.searchDebounce();
        },
    },
    created() {
        this.getTotalUserCount();
        this.getUsers();
    },

    methods: {
        ...mapMutations(["SET_USER", "UPDATE_SEARCH", "UPDATE_ADD_NEW"]),

		showFilter(){
			console.log('Showing Filter...');
			this.filter.visible = true
		},
		closeFilter(){
			console.log('Closing Filter...');
			this.filter.visible = false
		},
		applyFilter(filter){
			console.log('Applying Filter...', filter);
			this.filter.filter = filter
			this.searchDebounce();
		},
        searchDebounce: _.debounce(function () {
            this.currentPage = 1;
            this.getUsers();
            this.getTotalUserCount();
        }, 1000),

        async getTotalUserCount() {
            try {
                let filter = JSON.parse(JSON.stringify(this.filter.filter));
                let searchQuery = "";
				let emailQuery = "";
				let loginQuery = "";
				let companyQuery = "";
				let filterQuery= "";
                if (this.search) {
                    searchQuery = `?_q=${this.search}`;
				} else {
					if (filter.emails && filter.emails.length) {
						emailQuery = filter.emails.map(email => `email_in=${encodeURIComponent(email)}`).join('&');
						filterQuery += `${!filterQuery ? '?' : '&'}${emailQuery}`
					}
					if (filter.company && filter.company.length) {
						companyQuery = filter.company.map(company => `company_in=${encodeURIComponent(company)}`).join('&');
						filterQuery += `${!filterQuery ? '?' : '&'}${companyQuery}`
					}
					if (filter.loginType[0] !==  'any') {
						loginQuery = filter.loginType.map(type => `lastLoginMethod_in=${type}`).join('&');
						filterQuery += `${!filterQuery ? '?' : '&'}${loginQuery}`
					}
				}

                let { data } = await this.$api.get(
                    `super-admin/users/count${filterQuery}${searchQuery}`
                );
                this.totalUsers = data;
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching users count. Please try again!"))
				}
            }
        },
        async getUsers() {
            this.loading = true;
            try {
                let start = (this.currentPage - 1) * this.pageSize;
				let filter = JSON.parse(JSON.stringify(this.filter.filter));
                let searchQuery = "";
				let emailQuery = "";
				let loginQuery = "";
				let companyQuery = "";
				let filterQuery= "";
                if (this.search) {
                    searchQuery = `&_q=${this.search}`;
                } else {
					if (filter.emails && filter.emails.length) {
						emailQuery = filter.emails.map(email => `email_in=${encodeURIComponent(email)}`).join('&');
						filterQuery += `&${emailQuery}`
					}
					if (filter.company && filter.company.length) {
						companyQuery = filter.company.map(company => `company_in=${encodeURIComponent(company)}`).join('&');
						filterQuery += `&${companyQuery}`
					}
					if (filter.loginType[0] !==  'any') {
						loginQuery = filter.loginType.map(type => `lastLoginMethod_in=${type}`).join('&');
						filterQuery += `&${loginQuery}`
					}
				}

                let { data } = await this.$api.get(
                    `/super-admin/users?_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${filterQuery}${searchQuery}`
                );
                this.users = data.map((u, index) => {
                    return {
                        ...u,
                        key: index,
                    };
                });
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching user list. Please try again!"))
				}
            }
            this.loading = false;
        },

		formatDate,
		onSelectChange(selectedRowKeys, selectedRows) {
			this.selectedRowKeysPerPage[this.currentPage] = selectedRowKeys
			this.selectedRowKeys = selectedRowKeys;
			selectedRows.forEach(user => {
				this.selectedUsers[user.id] = user;
			});
		},
        editUser(user) {
            this.SET_USER(user);
            this.$router.push(`/users/${user.id}`);
        },

        handleChange(pagination, filter, sorter) {
			if (pagination) {
				this.currentPage = pagination.current;
				if (this.pageSize !== pagination.pageSize) {
					this.selectedRowKeysPerPage = {};
					this.selectedUsers = {};
				}
				this.pageSize = pagination.pageSize;
				this.selectedRowKeys = this.selectedRowKeysPerPage[this.currentPage] || [];
			}
            if (sorter && sorter.column) {
                if (
                    sorter.column.title === "Status" &&
                    sorter.order !== "descend"
                ) {
                    sorter.columnKey = "deleted";
                }
                this.sortedInfo = {
                    key: sorter.columnKey,
                    order: sorter.order === "descend" ? "DESC" : "ASC",
                };
            }
            this.getUsers();
        },

        resetPassword() {
            this.resetPasswordModal = false;
            this.$api
                .post(
                    `/super-admin/users/${this.selectedUser.id}/reset-password`
                )
                .then(() => {
                    this.$message.success(
                        "Successfully send reset password link"
                    );
                    this.selectedUser = {};
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while performing reset password action. Please try again!"));
					}
                });
        },
        validateEmail(rule, value, callback) {
            if (value === "") {
                callback(new Error("Please enter an email"));
            } else {
                const result =
                    /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(
                        value
                    );
                if (result) {
                    callback();
                } else {
                    callback(new Error("That's not a valid email address."));
                }
            }
        },

        validatePostalCodes(rule, value, callback) {
            if (value === "") {
                callback(new Error("Please enter a Zip/Postal Code"));
            } else {
                if (!this.user.country) {
                    callback(new Error("Please select a country first"));
                }
                const countryCode = countryData.countries[this.user.country];
                const result = postalCodes.validate(countryCode, value);
                if (result === true) {
                    callback();
                } else {
                    callback(new Error(result));
                }
            }
        },
        createUser() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.$api
                        .post(`/super-admin/users`, {
                            ...this.user,
                            username: this.user.email,
                        })
                        .then(() => {
                            this.confirmLoading = false;

                            this.$message.success(
                                "Successfully created new user"
                            );
                            this.UPDATE_ADD_NEW({
                                key: "users",
                                value: false,
                            });
                        })
                        .catch((err) => {
                            this.confirmLoading = false;
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, "Error while creating new user. Please try again!"));
							}
                        });
                } else {
                    console.error("Invalid form details");
                    return false;
                }
            });
        },
        req: (msg) => ({ required: true, message: msg }),

        getUserType(user) {
            const types = [];
            if (user.partners.length) {
                types.push("Partner");
            }
            if (user.ownInstances.length) {
                types.push("Customer");
            }
            if (!user.partners.length && !user.ownInstances.length) {
                types.push("Contributor");
            }

            return types;
        },

		resendVerification(user) {
			let self = this;
			this.$confirm({
				title: `Resend Email Verification`,
				content: (h) => <div>Do you want to resend user email verification link to <strong>{user.email}</strong>?</div>,
				okText: "RESEND",
				okType: "primary",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.loading = true;
					self.$api.post(`super-admin/users/${user.id}/resend-verification`).then(() => {
						self.loading = false;
						self.$message.success(`Successfully resent verification link to selected user.`);
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while sending verification link to user. Please try again.`));
						}
					})
				}
			});
		},

		downloadUsers() {
			let users = Object.values(this.selectedUsers);

			if (!users.length) {
				return this.$message.error('Please select a users first.')
			}
			this.$nprogress.start();
			users = users.map((u) => {
				return {
					username: u.username || '',
					email: u.email || '',
					company: u.company || '',
					createdAt: (u.createdAt && formatDate(u.createdAt)) || '',
					lastLoginMethod: this.providers[u.lastLoginMethod] || u.lastLoginMethod || this.providers[u.provider] || u.provider || '',
					lastLoginDate: (u.lastLoginDate && formatDate(u.lastLoginDate)) || '',
				}
			})

			const header = ['Username', 'Email', 'Company', 'Date Created', 'Last Login Method', 'Last Login Date',].join(',') + '\n';
			const rows = users.map(obj => Object.values(obj).join(',')).join('\n');

			// Create a link element to download the CSV file
			const url = window.URL.createObjectURL(new Blob([header + rows], { type: 'text/csv' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `users ${formatDate(new Date().toISOString())}.csv`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			this.selectedUsers = {};
			this.selectedRowKeysPerPage = {};
			this.selectedRowKeys = [];
			this.$nprogress.done();
		},
		async downloadAllUsers() {
			let filter = JSON.parse(JSON.stringify(this.filter.filter));
			let searchQuery = "";
			let emailQuery = "";
			let loginQuery = "";
			let companyQuery = "";
			let filterQuery= "";
			if (this.search) {
				searchQuery = `?_q=${this.search}`;
			} else {
				if (filter.emails && filter.emails.length) {
					emailQuery = filter.emails.map(email => `email_in=${encodeURIComponent(email)}`).join('&');
					filterQuery += `${!filterQuery ? '?' : '&'}${emailQuery}`
				}
				if (filter.company && filter.company.length) {
					companyQuery = filter.company.map(company => `company_in=${encodeURIComponent(company)}`).join('&');
					filterQuery += `${!filterQuery ? '?' : '&'}${companyQuery}`
				}
				if (filter.loginType[0] !==  'any') {
					loginQuery = filter.loginType.map(type => `lastLoginMethod_in=${type}`).join('&');
					filterQuery += `${!filterQuery ? '?' : '&'}${loginQuery}`
				}
			}

			this.$api.get(`/super-admin/users/download-csv${filterQuery}${searchQuery}`, { responseType: 'blob' }).then(response => {
				const blob = new Blob([response.data], { type: 'text/csv' });
				const link = document.createElement('a');
				const url = window.URL.createObjectURL(blob);
				link.href = url;
				link.setAttribute('download', `users ${new Date().toISOString()}.csv`);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
				this.$nprogress.done();
			}).catch(err => {
				this.$nprogress.done();
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "An error occurred while downloading the CSV. Please try again!"));
				}
			});
		}
    },

    beforeDestroy() {
        this.UPDATE_SEARCH("");
    },
};
</script>

<style lang="scss" scoped>
.popoverContent {
    height: max-content;
    line-height: 30px;
    padding: 0 10px;
}
.popoverContent:hover {
    background-color: var(--off-white-light);
    color: var(--primary);
    cursor: pointer;
}
.project-button {
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    padding: 4px 8px;
}
</style>

<style lang="scss">
.popoverStyle .ant-popover-inner-content {
    padding: 5px;
    background-color: white;
    border-radius: 20px;
}
</style>
